import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import * as styles from "../styles/404.module.scss"

function NotFoundPage() {
  const query = useStaticQuery(graphql`
    {
      allPrismicBoggsImageBucket {
        edges {
          node {
            data {
              error {
                alt
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `)

  const data = query.allPrismicBoggsImageBucket.edges[0].node.data

  return (
    <Layout>
      <SEO title="404: Not found" />
      <div className={styles.container}>
        <h1>Page not found</h1>
        <div className={styles.image_container}>
          <GatsbyImage
            className={styles.image}
            alt={data.error.alt}
            image={data.error.gatsbyImageData}
          />
        </div>
        <p>Sorry, you've gone to a page that doesn&#39;t exist...</p>
      </div>
    </Layout>
  )
}

export default NotFoundPage
